import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
import Loading from './views/loading'
import Privacy from './views/privacy'
import Terms from './views/terms'
// import i18n (needs to be bundled
import './i18n';
import { useTranslation } from 'react-i18next';

// vercel
import { Analytics } from '@vercel/analytics/react';

const LanguageRoutes = () => {
  const { i18n } = useTranslation();
  const { lng } = useParams();

  React.useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <Switch>
      <Route component={Home} exact path="/:lng" />
      <Route component={Privacy} path="/:lng/privacy" />
      <Route component={Terms} path="/:lng/terms" />
      <Route component={NotFound} path="/:lng/**" />
      <Redirect to={`/${lng}`} />
    </Switch>
  );
}

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Analytics></Analytics>
      <Router>
        <Switch>
          <Route path="/:lng(en|zh)">
            <LanguageRoutes />
          </Route>
          <Redirect to="/en" />
        </Switch>
      </Router>
    </Suspense>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
