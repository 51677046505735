import React from 'react'
import { Helmet } from 'react-helmet'

import './loading.css'

const Loading = (props) => {
  return (
    <>
      <div className="loading-container">
        <Helmet>
          <title>Loading ...</title>
        </Helmet>
        {/* <h3>HANG ON! PAGE IS LOADING</h3>
        <div className="loading-container1">
          <h1 className="loading-text1">LOADING</h1>
        </div>
        <div className="loading-container2">
          <h2 className="loading-text2">
            WAIT A SECOND, THE PAGE YOU REQUESTED IS LOADING
          </h2>
        </div> */}
      </div>
    </>
  )
}

export default Loading
