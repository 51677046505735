import React from 'react'
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet'

import Card from '../components/card'
import Footer from '../components/footer'
import NavBar from '../components/navbar'
import './home.css'

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const platformUrl = process.env.REACT_APP_PLATFORM_URL || "platform.commerbiz.com";

  return (
    <div className="home-container">
      <Helmet>
        <title>CommerBiz 最低0元建立電商</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta property="og:title" content="CommerBiz 最低0元建立電商" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.commerbiz.com/og-image.png" />
        <meta property="og:description" content="依流量收費，每月最低0元，輕鬆開店" />
        <script type="application/ld+json">{JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "CommerBiz 最低0元建立電商",
          "description": "CommerBiz 提供最低每月 0 元建站方案，依實際流量付費，並保證 0% 訂單抽成，幫助商家輕鬆經營電商。",
          "url": "https://www.commerbiz.com",
          "mainEntity": {
            "@type": "Organization",
            "name": "CommerBiz",
            "description": "CommerBiz 是一個專注於幫助用戶建立電商網站的平台，無需支付每月固定成本，按實際流量付費，讓商家擁有完整的收益。",
            "url": "https://www.commerbiz.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.commerbiz.com/commerbiz-logo-wo-name.png"
            }
          },
          "publisher": {
            "@type": "Organization",
            "name": "CommerBiz",
            "url": "https://www.commerbiz.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.commerbiz.com/commerbiz-logo-wo-name.png"
            }
          },
          "image": {
            "@type": "ImageObject",
            "url": "https://www.commerbiz.com/og-image.png",
            "height": 539,
            "width": 1045
          },
          "datePublished": "2024-09-26",
          "inLanguage": "zh-TW"
        })}</script>
      </Helmet>
      <NavBar></NavBar>
      <header className="home-hero">
        <div className="home-content">
          <h1 className="home-title">{t('hero.title')}</h1>
          <span className="home-description">
            {t('hero.minimum_fee')} <br></br>
            {t('hero.pay_for_visit')} <br></br>
            {t('hero.flexible_pricing')}
          </span>
          <img alt="image" src="/line-300w.png" className="home-image" />
        </div>
        <div className="home-image01">
          <img
            alt="image"
            src="/e-commerce3.png"
            className="home-image02"
          />
        </div>
      </header>
      <section className="home-mission">
        <h2 className="home-text08">
          {t('join.title')} <br></br>
          <span style={{ fontSize: 18 }}>
            {t('join.0%_order_fee')} <br></br>
            {t('join.keep_all_profits')}
          </span>
        </h2>
        <a href={`https://${platformUrl}/admin/signup`}>
          <button className="button home-button06">
            <span className="home-text09">{t('join.create_account')}</span>
          </button>
        </a>
      </section>
      <section className="home-improve" id='contact'>
        <div className="home-tabs">
          <div className="home-content1">
            <div className="home-details">
              <div className="home-header">
                <h2 className="home-text12">{t('customised_service.title1')} <br></br>{t('customised_service.title2')}</h2>
                <div className="home-description1">
                  <p className="home-text13">
                    {t('customised_service.response_policy')}
                  </p>
                  <p className="home-text14">
                    {t('customised_service.reach_out')}
                  </p>
                </div>
              </div>
              <button className="button" onClick={() => { window.Tawk_API.maximize(); }}>
                <span className="home-text15">
                  <span>{t('customised_service.inquiry')}</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-image04">
              <img
                alt="image"
                src="/e-commerce5.png"
                className="home-image05"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-pricing" id='pricing'>
        <h2 className="home-text18">{t('pricing.title')}</h2>
        <p>{t('pricing.description')}</p>
        <div className="pricing-table">
          <div className="pricing-card">
            <h2 style={{ background: 'rgb(255 236 163)' }}>{t('pricing.basic.title')}</h2>
            <p className="price">{t('pricing.basic.price_start_from')} <b style={{ fontSize: 18 }}>{t('pricing.basic.price_start_at')}</b>/{t('pricing.basic.price_start_at_per_month')}</p>
            <p>· {t('pricing.basic.item1')}</p>
            <p>· {t('pricing.basic.item2')}</p>
            <p>· {t('pricing.basic.item3')}</p>
            <p>· {t('pricing.basic.item4')}</p>
            <p>· {t('pricing.basic.item5')}</p>
            <p>· {t('pricing.basic.item6')}</p>
            <p>· {t('pricing.basic.item7')}</p>
          </div>

          <div className="pricing-card design-dev">
            <h2 style={{ background: 'rgb(255 227 120)', position: 'relative' }}>
              <div style={{ position: 'absolute', fontSize: 12, top: -11, backgroundColor: '#fc91ed', color: 'white', padding: '5px 10px', borderRadius: 12 }}>{t('pricing.advanced.recommended')}</div>
              {t('pricing.advanced.title')}
            </h2>
            <p className="price"><b style={{ fontSize: 18 }}>{t('pricing.advanced.price_range')}</b>/{t('pricing.advanced.price_unit')}</p>
            <p>· {t('pricing.advanced.item1')}</p>
            <p>· {t('pricing.advanced.item2')}</p>
            <p>· {t('pricing.advanced.item3')}</p>
            <p>· {t('pricing.advanced.item4')}</p>
            <p>· {t('pricing.advanced.item5')}</p>
            <p>· {t('pricing.advanced.item6')}</p>
          </div>

          <div className="pricing-card webflow-dev">
            <h2 style={{ background: 'rgb(255 215 64)' }}>{t('pricing.enterprise.title')}</h2>
            <p className="price"><b style={{ fontSize: 18 }}>{t('pricing.enterprise.price_range')}</b>/{t('pricing.enterprise.price_unit')}</p>
            <p>· {t('pricing.enterprise.item1')}</p>
            <p>· {t('pricing.enterprise.item2')}</p>
            <p>· {t('pricing.enterprise.item3')}</p>
            <p>· {t('pricing.enterprise.item4')}</p>
            <p>· {t('pricing.enterprise.item5')}</p>
          </div>

          <div className="pricing-card refer">
            <h2>{t('pricing.reference_program.title')}</h2>
            <p>{t('pricing.reference_program.description')}</p>
            <p style={{ lineHeight: 2 }}>
              {t('pricing.reference_program.condition')}
              <span style={{ whiteSpace: 'nowrap', fontSize: 12, backgroundColor: 'rgb(255 227 120)', color: 'gray', padding: '5px 10px', borderRadius: 12, marginRight: 4 }}>{t('pricing.advanced.title')}</span>
              {t('pricing.reference_program.reward')}
            </p>
          </div>
        </div>
      </section>
      <section className="home-services" id="feature">
        <h2 className="home-text18">{t('feature.title')}</h2>
        <div className="home-cards">
          <div className="home-row">
            <Card
              Icon="/fee-icon.png"
              Title={t('feature.minimum_fee.title')}
              Description={t('feature.minimum_fee.description')}
            ></Card>
            <Card
              Icon="/service-charge-icon.png"
              Title={t('feature.order_fee.title')}
              Description={t('feature.order_fee.description')}
            ></Card>
          </div>
          <div className="home-row1">
            <Card
              Icon="/custom-service-icon.png"
              Title={t('feature.customised_service.title')}
              Description={t('feature.customised_service.description')}
            ></Card>
            <Card
              Icon="/store-icon.png"
              Title={t('feature.storefront_editor.title')}
              Description={t('feature.storefront_editor.description')}
            ></Card>
          </div>
        </div>
      </section>
      {/* Block for partners logo */}
      {/* <section className="home-partners">
        <div className="home-content3">
          <h2 className="home-text33">Our partners</h2>
          <p className="home-text34">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>
        <div className="home-partners-desktop">
          <div className="home-column">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column01">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column02">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B5%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column03">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
        </div>
        <div className="home-partners-mobile">
          <div className="home-row2">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B5%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column04">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202107.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column05">
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202111.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202108.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/group%202110.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B1%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
          <div className="home-column06">
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B2%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B3%5D.svg"
                className="partner-image"
              />
            </div>
            <div className="partner-container">
              <img
                alt="image"
                src="/vector%5B4%5D.svg"
                className="partner-image"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* Block for case study */}
      {/* <section className="home-services1">
        <div className="home-heading1">
          <h2 className="home-text35">Take a look at the latest articles</h2>
          <p className="home-text36">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>
        <div className="home-sections">
          <div className="home-row3">
            <div className="home-section">
              <div className="home-header1">
                <h3 className="home-text37">Understand your customers</h3>
                <div className="home-description2">
                  <p className="home-text38">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text39">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-section1">
              <div className="home-header2">
                <h3 className="home-text43">Own your business</h3>
                <div className="home-description3">
                  <p className="home-text44">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text45">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
          </div>
          <div className="home-row4">
            <div className="home-section2">
              <div className="home-header3">
                <h3 className="home-text49">How to build your team</h3>
                <div className="home-description4">
                  <p className="home-text50">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text51">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
            <div className="home-section3">
              <div className="home-header4">
                <h3 className="home-text55">We can help your business</h3>
                <div className="home-description5">
                  <p className="home-text56">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p className="home-text57">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <button className="button">
                <span>
                  <span>Read more</span>
                  <br></br>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <Footer text={t('footer.intro')}></Footer>
      {/* <div>
        <div className="home-container6">
          <Script
            html={`<style>
    .partner-container:hover .partner-image{
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(101%) contrast(102%);
    }
</style>`}
          ></Script>
        </div>
      </div> */}
    </div>
  )
}

export default Home
