import React from 'react'

import PropTypes from 'prop-types'

import Branding from './branding'
import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-information">
        <div className="footer-content">
          <div className="footer-header">
            <Branding></Branding>
            <p className="footer-text">{props.text}</p>
          </div>
          <div className="footer-contact-list">
            <div className="footer-contact">
              <img
                alt={props.image_alt}
                src={props.image_src}
                className="footer-image"
              />
              <span className="footer-text01">{props.text1}</span>
            </div>
          </div>
        </div>
        <span className="footer-copyright">{props.Copyright}</span>
      </div>
      {/* Social links */}
      {/* <div className="footer-links">
        <div className="footer-column">
          <span className="link">{props.linkedin}</span>
          <span className="link">{props.ig}</span>
        </div>
        <div className="footer-column1">
          <span className="link">{props.twitter}</span>
          <span className="link">{props.facebook}</span>
        </div>
      </div> */}
    </footer>
  )
}

Footer.defaultProps = {
  Text5: 'Contact',
  facebook: 'Facebook',
  Text1: 'Training\n',
  Copyright: '©2024 CommerBiz. All Rights Reserved.',
  image_alt: 'image',
  Text: 'About',
  text: 'Run your store efficiently without fixed monthly cost.',
  image_src: '/group%201639.svg',
  Text3: 'Membership',
  text1: 'theplutusteam@gmail.com',
  twitter: 'Twitter',
  linkedin: 'Linkedin',
  image_alt1: 'image',
  image_src1: '/group%201640.svg',
  ig: 'Instagram',
}

Footer.propTypes = {
  Text5: PropTypes.string,
  Text9: PropTypes.string,
  Text1: PropTypes.string,
  Copyright: PropTypes.string,
  image_alt: PropTypes.string,
  Text: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
  Text3: PropTypes.string,
  text1: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src1: PropTypes.string,
  ig: PropTypes.string,
}

export default Footer
