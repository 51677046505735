import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/navbar'

import './privacy.css'

const Terms = (props) => {
  return (
    <>
      <NavBar></NavBar>
      <div className="terms-privacy-container">
        <div className="terms-privacy-hero">
          <div className="terms-privacy-content">
            <Helmet>
              <title>服務條款</title>
              <script type="application/ld+json">{JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "服務條款",
                "description": "CommerBiz 提供的服務條款。歡迎使用我們的服務，請仔細閱讀以下條款。",
                "url": "https://www.commerbiz.com/zh/terms",
                "mainEntity": {
                  "@type": "LegalService",
                  "name": "CommerBiz",
                  "description": "CommerBiz 提供電商平台和相關服務的條款和條件。包括網站建設、支付處理、訂單管理、客戶支援等。",
                  "termsOfService": "https://www.commerbiz.com/zh/terms"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "CommerBiz",
                  "url": "https://www.commerbiz.com",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.commerbiz.com/commerbiz-logo-wo-name.png"
                  }
                },
                "datePublished": "2024-09-26",
                "inLanguage": "zh-TW"
              })}</script>
            </Helmet>
            <h1>服務條款</h1>
            <p>歡迎使用 CommerBiz（以下稱為“我們”或“本公司”）的服務。請仔細閱讀以下服務條款（以下稱為“條款”），這些條款適用於您使用我們的網站和服務。如果您不同意這些條款，請不要使用我們的服務。</p>

            <h2>服務說明</h2>
            <p>我們提供一個電商平台，讓用戶能夠建立和管理自己的電商網站。我們的服務包括但不限於網站建設工具、支付處理、訂單管理、客戶支援等。</p>

            <h2>用戶資格</h2>
            <p>使用我們的服務，您必須年滿18歲，並且具有法律約束力的合同能力。通過註冊和使用我們的服務，您聲明並保證您符合這些要求。</p>

            <h2>註冊和帳戶安全</h2>
            <p>您需要註冊一個帳戶才能使用我們的大部分服務。註冊過程中，您必須提供準確和完整的信息，並在信息變更時及時更新。您有責任保護您的帳戶信息和密碼的安全，並對所有在您的帳戶下發生的活動負責。如果您發現任何未經授權的使用，請立即通知我們。</p>

            <h2>使用規則</h2>
            <ul>
              <li>使用我們的服務從事任何非法活動</li>
              <li>侵害他人的知識產權或隱私權</li>
              <li>發布虛假或誤導性信息</li>
              <li>干擾或破壞我們的服務或伺服器</li>
            </ul>

            <h2>費用和付款</h2>
            <p>某些服務可能需要支付費用。所有費用將在您註冊或使用相關服務時明確告知。您同意按時支付所有應付費用，並授權我們通過您提供的支付方式收取這些費用</p>

            <h2>取消和終止</h2>
            <p>您可以隨時取消您的帳戶，並停止使用我們的服務。我們保留在任何時間，基於我們的判斷，暫停或終止您的帳戶或您對我們服務的訪問權利，無需事先通知。取消或終止後，您仍有責任支付任何未結清的費用。</p>

            <h2>知識產權</h2>
            <p>我們擁有我們網站和服務的所有內容和材料的知識產權，包括但不限於文本、圖像、商標和標誌。未經我們書面許可，您不得使用、複製、修改或分發這些內容和材料。</p>

            <h2>隱私</h2>
            <p>我們尊重您的隱私，並按照我們的隱私政策處理您的個人信息。使用我們的服務即表示您同意我們的隱私政策。</p>

            <h2>責任限制</h2>
            <p>在適用法律允許的最大範圍內，本公司對於因使用或無法使用我們的服務而引起的任何直接、間接、附帶、特殊、後果性或懲罰性損害不承擔責任，即使我們已被告知此類損害的可能性。</p>

            <h2>賠償</h2>
            <p>您同意賠償並保護我們免受任何因您使用我們的服務、違反這些條款或侵害任何第三方權利而引起的索賠或要求（包括合理的律師費用）。</p>

            <h2>變更條款</h2>
            <p>我們保留在任何時候修改這些條款的權利。修改後的條款將在我們的網站上發布，並在發布後即時生效。您繼續使用我們的服務即表示您接受修改後的條款。</p>
          </div>
        </div>
      </div>

      <div style={{ padding: 10 }}>
      </div>
    </>

  )
}

export default Terms
