import React from 'react'

import PropTypes from 'prop-types'

import './branding.css'

const Branding = (props) => {
  return (
    <div className="branding-branding">
      <span className="branding-text">{props.text}</span>
      <img src='/commerbiz-logo-wo-name.png' className="branding-icon"></img>
    </div>
  )
}

Branding.defaultProps = {
  text: 'CommerBiz',
}

Branding.propTypes = {
  text: PropTypes.string,
}

export default Branding
